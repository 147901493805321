import * as React from 'react';
import { useState } from 'react';
import ReactMapGL, {
  Popup,
  Marker,
  NavigationControl,
  FullscreenControl,
  ScaleControl,
  GeolocateControl,
} from 'react-map-gl';

import LogoPin from '../../images/pin.png';

const navStyle = {
  position: 'absolute',
  top: 72,
  left: 0,
  padding: '10px',
};

const scaleControlStyle = {
  position: 'absolute',
  bottom: 36,
  left: 0,
  padding: '10px',
};

function Map() {
  const [viewport, setViewport] = useState({
    latitude: 54.275888,
    longitude: 20.527356,
    zoom: 12,
  });

  return (
    <ReactMapGL
      {...viewport}
      onViewportChange={nextViewport => setViewport(nextViewport)}
      width="75vw"
      height="75vh"
      mapStyle="mapbox://styles/mapbox/streets-v11"
      mapboxApiAccessToken="pk.eyJ1Ijoia296NCIsImEiOiJja2JmY3pva20wODZtMnltbDN6N253NGxoIn0.PBsfQWVsfPLhJhU3EjeT-Q"
    >
      <Marker latitude={54.275888} longitude={20.527356} offsetLeft={-20} offsetTop={-10}>
        <img src={LogoPin} />
      </Marker>
      <div style={navStyle}>
        <NavigationControl />
      </div>
      <div style={scaleControlStyle}>
        <ScaleControl />
      </div>
    </ReactMapGL>
  );
}

export default Map;
