import React from 'react';
import { Helmet } from 'react-helmet';
import Info from '../components/Info';
import { headData } from '../mock/data';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../style/main.scss';

export default () => {
  const { title, lang, description } = headData;

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title || 'Brand Soda System'}</title>
        <html lang={lang || 'pl'} />
        <meta name="description" content={description || 'Brand Soda System'} />
        <meta
          name="keywords"
          content="BSS, Brand Soda System, bssoda, sodowanie, szkiełkowanie, piaskowanie, malowanie, oczyszczanie, tynkowanie maszynowe, anty korozja, murowanie"
        />
      </Helmet>
      <Info />
    </>
  );
};
