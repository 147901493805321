import React, { useState, useEffect } from 'react';
import Kontakt from './Kontakt/Kontakt';
import Footer from './Footer/Footer';
import Nav from './Nav/Nav';


import { PortfolioProvider } from '../context/context';

import {  footerData, logoData } from '../mock/data';

function Info() {
  const [footer, setFooter] = useState({});
  const [logo, setLogo] = useState({});

  useEffect(() => {
    setLogo({ ...logoData });
    setFooter({ ...footerData });
  }, []);

  return (
    <PortfolioProvider value={{ logo, footer }}>
      <Nav />
      <Kontakt />
      <Footer />
    </PortfolioProvider>
  );
}

export default Info;
