import React, { useContext } from 'react';
import { Container, Form, Button } from 'react-bootstrap';
import styled from 'styled-components';
import { Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import PortfolioContext from '../../context/context';
import Title from '../Title/Title';
import Logo from '../Image/Logo';
import Map from '../Map/Map';
import { GoMail } from 'react-icons/go';
import { GiSmartphone } from 'react-icons/gi';
// Styled-components styles
const MAPWRAP = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  padding-bottom: 2em;
`;

const BIGKON = styled.div`
  border-radius: 30px 0px 0px 30px;
  height: auto;
  width: 100%;
  margin: 1em auto;
`;

const ADRES = styled.div`
  font-size: 24px;
`;

const KONTENER = styled.div`
vertical-align: text-top;
  height: 100%;
  width: 100%;

  @media (min-width: 786px) {
    width: 100%;
  }

  label {
    color: #333333;
  }

  h1 {
    color: #333333;
    padding-top: 2em;
    padding-bottom: 1em;
  }
  a {
    color: orangered;
  }

  .form-group {
    margin-bottom: 1em;
  }

  .error {
    border: 2px solid #ff6565;
  }

  .error-message {
    color: #ff6565;
    padding: 0.1em 0.5em;
    height: 1em;
    position: absolute;
    font-size: 0.7em;
  }
`;

const MYFORM = styled(Form)`
  width: 100%;
  font-size: 18px;
  text-align: left;
  padding-top: 0.1em;
  padding-bottom: 0.5em;

  @media (min-width: 786px) {
    width: 90%;
  }
`;

const BUTTON = styled(Button)`
  background-color: white;
  color: orange;
  border: 2px solid $white-color;
  margin: auto;
  border-radius: 0;
  border-color: white;
  display: block;
  position: relative;
  font-size: 20px;
  padding: 0.8rem $default-font-size;
  font-weight: bold;
  z-index: 1;
  transition: all cubic-bezier(0.19, 1, 0.22, 1) 0.6s;

  &:hover {
    text-color: white;
    background-color: orange;
    border-color:orange;
    text-decoration: none;
  }
  &:focus {
    text-color: white;
    background-color: orange;
    border-color: orange;
    text-decoration: none;
  }
`;

// RegEx for phone number validation
const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

// Schema for yup
const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, '*Pole imię i nazwisko powinno posiadać min 2 znaki')
    .max(100, '*Pole imię i nazwisko nie powinno być dłusze niz 100 znaków')
    .required('*Pole jest wymagane'),
  email: Yup.string()
    .email('*Email musi spełniać kryteria adresu')
    .max(100, '*Email nie powinien być dłuszy niz 100 znaków')
    .required('*Email jest wymagany'),
  phone: Yup.string()
    .matches(phoneRegExp, '*Numer telefonu jest niepoprawny')
    .required('*Numer telefonu jest wymagany'),
  wiadomosc: Yup.string()
    .min(4, '*Pole wiadomość powinno posiadać min 4 znaki')
    .max(250, '*Pole wiadomość nie powinno być dłusze niz 250 znaków')
    .required('*Pole jest wymagane'),
});

const Kontakt = () => {
  const { logo } = useContext(PortfolioContext);
  const { img } = logo;

  return (
    <>
      <section id="cien_galeria">
        <section id="galeria">
          <Title title="Kontakt" />
        </section>
      </section>
      <Container>
        <BIGKON>
          <div className="row">
            <div className="col">
              <KONTENER>
              <h1>Zadaj nam pytanie przez formularz</h1>
                <Formik
                  initialValues={{ name: '', email: '', phone: '', wiadomosc: '' }}
                  validationSchema={validationSchema}
                  onSubmit={(values, { setSubmitting, resetForm }) => {
                    // When button submits form and form is in the process of submitting, submit button is disabled
                    setSubmitting(true);
                    axios({
                      method: "POST",
                      url: "https://formspree.io/mleplwnp",
                      data: values
                    })
                      .then(response => {
                        setSubmitting(false);
                        resetForm();
                        handleServerResponse(true, "Dziękujemy za kontakt!");
                      })
                      .catch(error => {
                        setSubmitting(false);
                      });

                    // Simulate submitting to database, shows us values submitted, resets form
                    setTimeout(() => {
                      alert("Dziękujemy, niedługo się z Tobą skontaktujemy.");
                      resetForm();
                      setSubmitting(false);
                    }, 500);
                  }}
                >
                  {/* Callback function containing Formik state and helpers that handle common form actions */}
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                  }) => (
                    <MYFORM onSubmit={handleSubmit} className="mx-auto">
                      <Form.Group controlId="formName">
                        <Form.Label>Imię i nazwisko :</Form.Label>
                        <Form.Control
                          size="lg"
                          type="text"
                          /* This name property is used to access the value of the form element via values.nameOfElement */
                          name="name"
                          placeholder="Imię i nazwisko"
                          /* Set onChange to handleChange */
                          onChange={handleChange}
                          /* Set onBlur to handleBlur */
                          onBlur={handleBlur}
                          /* Store the value of this input in values.name, make sure this is named the same as the name property on the form element */
                          value={values.name}
                          /* Check if the name field (this field) has been touched and if there is an error, if so add the .error class styles defined in the CSS (make the input box red) */
                          className={touched.name && errors.name ? 'error' : null}
                        />
                        {/* Applies the proper error message from validateSchema when the user has clicked the element and there is an error, also applies the .error-message CSS class for styling */}
                        {touched.name && errors.name ? (
                          <div className="error-message">{errors.name}</div>
                        ) : null}
                      </Form.Group>
                      <Form.Group controlId="formEmail">
                        <Form.Label>Email :</Form.Label>
                        <Form.Control
                          size="lg"
                          type="text"
                          name="email"
                          placeholder="Email"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.email}
                          className={touched.email && errors.email ? 'error' : null}
                        />
                        {touched.email && errors.email ? (
                          <div className="error-message">{errors.email}</div>
                        ) : null}
                      </Form.Group>
                      <Form.Group controlId="formPhone">
                        <Form.Label>Numer telefonu :</Form.Label>
                        <Form.Control
                          size="lg"
                          type="text"
                          name="phone"
                          placeholder="Telefon"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.phone}
                          className={touched.phone && errors.phone ? 'error' : null}
                        />
                        {touched.phone && errors.phone ? (
                          <div className="error-message">{errors.phone}</div>
                        ) : null}
                      </Form.Group>
                      <Form.Group controlId="formWiadomosc">
                        <Form.Label>Wiadomość :</Form.Label>
                        <Form.Control 
                          as="textarea" 
                          rows="3"
                          size="lg"
                          type="text"
                          name="wiadomosc"
                          placeholder="Wiadomość"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.wiadomosc}
                          className={touched.wiadomosc && errors.wiadomosc ? 'error' : null}
                        />
                        {touched.wiadomosc && errors.wiadomosc ? (
                          <div className="error-message">{errors.wiadomosc}</div>
                        ) : null}
                      </Form.Group>
                       <button className="cta-btn cta-btn--hero mx-auto d-block" type="submit" disabled={isSubmitting}>
                        Wyślij
                      </button>
                    </MYFORM>
                  )}
                </Formik>
                </KONTENER>
            </div>
            <div className="col">
            <KONTENER>
            <h1>Adres korespondencyjny</h1>
            <Logo alt="logo" filename={img} /><br/>
              <ADRES>
              <address>
  <strong>BRAND SODA SYSTEM</strong><br />
  Piasty Wielkie 15<br/>
  11-220 Górowo Iławeckie<br /><GiSmartphone />
  Telefon: 533-917-983
</address>
NIP działalności: 7431953286
<address>
  <strong>Marek Migas</strong><br /><GoMail />
  E-mail: <a href="mailto:biuro@bssoda.pl">biuro@bssoda.pl</a>
</address>
</ADRES>
              </KONTENER>
            </div>
           
          </div>
        </BIGKON>
        <div className="row">
          <div className="col-sm-12">
          <KONTENER>
          <h1>Lokalizacja firmy</h1>
            <MAPWRAP>
              <Map />
            </MAPWRAP>
            </KONTENER>
          </div>
        </div>
      </Container>
    </>
  );
};
export default Kontakt;
